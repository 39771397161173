import React, { HTMLAttributes, useState } from "react";
import { FrontMemberService, LoginQuery } from "lib/service/client/FrontMemberService";
import { Field, Form, Formik } from "formik";
import Link from "next/link";
import { useRouter } from "next/router";
import { ValidationErrorV2 } from "lib/service/BaseClientService";
import { AuthErrorMessage } from "../../common/AuthErrorMessage";
import { FrontFormErrorArea } from "../../common/FrontFormErrorArea";
import { FormikHelpers } from "formik/dist/types";
import SsHeadline from "../layout/SsHeadline";
import ContentSection from "components/front/layout/ContentSection";
import styled from "styled-components";

export type Props = HTMLAttributes<HTMLElement>;
const LoginSection: React.FC<Props> = ({ ...props }) => {
  const router = useRouter();
  const [validationError, setValidationError] = useState<ValidationErrorV2>({});
  const [isAuthError, setIsAuthError] = useState<boolean>(false);
  const login = async (query: LoginQuery, helper: FormikHelpers<LoginQuery>): Promise<void> => {
    await memberService.login(query, router, setIsAuthError, (validationError: ValidationErrorV2) => {
      helper.setErrors(validationError);
      setValidationError(validationError);
    });
    helper.setSubmitting(false);
  };
  return (
    <ContentSection {...props} $pcWidth="350px">
      <Formik initialValues={initialValue} onSubmit={login}>
        {({ isSubmitting }): React.ReactNode => (
          <Form>
            <SsHeadline label="ログイン" color="black" />
            <AuthErrorMessage isAuthError={isAuthError} />
            <FrontFormErrorArea validationError={validationError} />
            <div className="form form--login">
              <div className="form--item">
                <p className="form--item_name form--item_name--mail">メールアドレス</p>
                <div className="form--item_input form--item_input--black">
                  <div className="form--item_input_bg" />
                  <Field placeholder="メールアドレス" type="email" label="メールアドレス" name="email" maxLength="100" />
                </div>
              </div>
              <div className="form--item">
                <p className="form--item_name form--item_password">
                  パスワード<span>※半角英数字記号8文字以上</span>
                </p>
                <div className="form--item_input form--item_input--black">
                  <div className="form--item_input_bg" />
                  <Field placeholder="パスワードを入力" label="パスワード" type="password" name="password" maxLength="50" />
                </div>
              </div>
              <div className="form_submit">
                <div className="link link--submit">
                  <div className="link--submit--item">
                    <div className="link--submit--item_bg" />
                  </div>
                  <div className="link--submit--item">
                    <div className="link--submit--item_bg" />
                  </div>
                  <div className="link--submit--item">
                    <div className="link--submit--item_bg" />
                  </div>
                  <div className="link--submit--item">
                    <div className="link--submit--item_bg" />
                  </div>
                  <div className="link--submit--item">
                    <div className="link--submit--item_bg" />
                  </div>
                  <div className="link--submit--item link_text">
                    <div className="link--submit--item_bg" />
                    <Input disabled={isSubmitting} value="ログイン" type="submit" />
                  </div>
                </div>
              </div>
              <Link href="/password/reset">
                <a className="form_lost">パスワードをお忘れの方</a>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </ContentSection>
  );
};
export default LoginSection;

const initialValue: LoginQuery = { email: "", password: "" };
const memberService = new FrontMemberService();
const Input = styled.input`
  color: black;
`;
